
// import api from '@/api'
import { IObject } from '@/types/inerface'
// import { AxiosRequestConfig } from 'axios'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import cityList from '@/utils/cityList'
import list from '@/utils/index.list'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    const indexList = list.map((item) => {
      return item.letter
    })
    const hotCity = [
      { id: "73", city: "北京市" },
      { id: "85", city: "广州市" },
      { id: "60", city: "深圳市" },
      { id: "56", city: "东莞市" },
      { id: "80", city: "上海市" },
      { id: "55", city: "成都市" },
      { id: "280", city: "珠海市" },
      { id: "67", city: "中山市" },
      { id: "65", city: "佛山市" },
    ]
    let state = reactive({
      searchValue: '',
      list: list,
      baseList: list,
      loading: false,
      finished: true
    })
    const onLoad = () => {
      console.log('onLoad 123')
    }
    // const handleGetCityList = () => {
    //   api.bus.cityList().then((res: AxiosRequestConfig['data']) => {
    //     if (res?.code === 0) {
    //       // console.log(res)
    //       state.list = res.data
    //       state.baseList = res.data
    //     }
    //   })
    // }
    // onMounted(() => {
    //   handleGetCityList()
    // })
    const handleSearch = (val:string) => {
      if (val === '') {
        state.list = state.baseList
      } else {
        // state.list = state.list?.filter((item) => {
        //   // console.log(item)
        //   if (item.city.indexOf(val) !== -1) {
        //     console.log(item)
        //     return item
        //   }
        // })
      }
    }

    const changeCity = (city: IObject) => {
      console.log(city)
      store.commit('currentCity', city)
      router.push('/')
    }

    return {
      hotCity,
      indexList,
      changeCity,
      onLoad,
      handleSearch,
      ...toRefs(state)
    }
  },
})
