const list = [
  {
    letter: "A",
    data: [
      {
        id: "341",
        city: "阿城市",
      },
      {
        id: "132",
        city: "阿勒泰地区阿勒泰市",
      },
      {
        id: "372",
        city: "安宁市",
      },
      {
        id: "337",
        city: "安庆市",
      },
      {
        id: "345",
        city: "安顺市",
      },
      {
        id: "76",
        city: "安阳市",
      },
      {
        id: "112",
        city: "鞍山市",
      },
      {
        id: "220",
        city: "鞍山市岫岩满族自治县",
      },
    ],
  },
  {
    letter: "B",
    data: [
      {
        id: "271",
        city: "巴彦淖尔市",
      },
      {
        id: "331",
        city: "巴中市",
      },
      {
        id: "343",
        city: "白山市",
      },
      {
        id: "386",
        city: "白银市",
      },
      {
        id: "108",
        city: "包头市",
      },
      {
        id: "285",
        city: "宝泉岭市",
      },
      {
        id: "110",
        city: "保定市",
      },
      {
        id: "315",
        city: "北海市",
      },
      {
        id: "73",
        city: "北京市",
      },
      {
        id: "352",
        city: "毕节市",
      },
      {
        id: "148",
        city: "滨州市",
      },
      {
        id: "264",
        city: "亳州市",
      },
      {
        id: "117",
        city: "博尔塔拉蒙古自治州精河县市",
      },
      {
        id: "346",
        city: "博乐市",
      },
    ],
  },
  {
    letter: "C",
    data: [
      {
        id: "119",
        city: "沧州市",
      },
      {
        id: "87",
        city: "昌吉回族自治州奇台县市",
      },
      {
        id: "105",
        city: "长春市",
      },
      {
        id: "107",
        city: "长沙市",
      },
      {
        id: "124",
        city: "长治市",
      },
      {
        id: "102",
        city: "常州市",
      },
      {
        id: "138",
        city: "潮州市",
      },
      {
        id: "272",
        city: "郴州市",
      },
      {
        id: "55",
        city: "成都市",
      },
      {
        id: "278",
        city: "承德市",
      },
      {
        id: "385",
        city: "赤壁市",
      },
      {
        id: "270",
        city: "赤峰市",
      },
      {
        id: "310",
        city: "滁州市",
      },
      {
        id: "338",
        city: "楚雄市",
      },
    ],
  },
  {
    letter: "D",
    data: [
      {
        id: "361",
        city: "达州市",
      },
      {
        id: "104",
        city: "大连市",
      },
      {
        id: "246",
        city: "大同市",
      },
      {
        id: "159",
        city: "德阳市",
      },
      {
        id: "149",
        city: "德州市",
      },
      {
        id: "295",
        city: "调兵山市",
      },
      {
        id: "298",
        city: "东海市",
      },
      {
        id: "56",
        city: "东莞市",
      },
      {
        id: "305",
        city: "东兴市",
      },
      {
        id: "146",
        city: "东营市",
      },
      {
        id: "319",
        city: "都江堰市",
      },
    ],
  },
  {
    letter: "E",
    data: [
      {
        id: "364",
        city: "额敏市",
      },
      {
        id: "367",
        city: "鄂尔多斯市",
      },
    ],
  },
  {
    letter: "F",
    data: [
      {
        id: "326",
        city: "奉化市",
      },
      {
        id: "65",
        city: "佛山市",
      },
      {
        id: "92",
        city: "福州市",
      },
      {
        id: "222",
        city: "抚顺市",
      },
      {
        id: "263",
        city: "阜阳市",
      },
    ],
  },
  {
    letter: "G",
    data: [
      {
        id: "350",
        city: "甘孜市",
      },
      {
        id: "84",
        city: "赣州市",
      },
      {
        id: "370",
        city: "高平市",
      },
      {
        id: "232",
        city: "固原市",
      },
      {
        id: "286",
        city: "广汉市",
      },
      {
        id: "85",
        city: "广州市",
      },
      {
        id: "165",
        city: "贵港市",
      },
      {
        id: "120",
        city: "贵阳市",
      },
      {
        id: "289",
        city: "桂平市",
      },
    ],
  },
  {
    letter: "H",
    data: [
      {
        id: "126",
        city: "哈尔滨市",
      },
      {
        id: "154",
        city: "海口市",
      },
      {
        id: "291",
        city: "海林市",
      },
      {
        id: "375",
        city: "海宁市",
      },
      {
        id: "379",
        city: "海盐市",
      },
      {
        id: "71",
        city: "邯郸市",
      },
      {
        id: "53",
        city: "杭州市",
      },
      {
        id: "339",
        city: "河池市",
      },
      {
        id: "325",
        city: "河源市",
      },
      {
        id: "151",
        city: "菏泽市",
      },
      {
        id: "340",
        city: "贺州市",
      },
      {
        id: "279",
        city: "衡水市",
      },
      {
        id: "376",
        city: "侯马市",
      },
      {
        id: "94",
        city: "呼和浩特市",
      },
      {
        id: "143",
        city: "呼伦贝尔市",
      },
      {
        id: "106",
        city: "淮安市",
      },
      {
        id: "139",
        city: "淮南市",
      },
      {
        id: "333",
        city: "惠水市",
      },
      {
        id: "62",
        city: "惠州市",
      },
    ],
  },
  {
    letter: "J",
    data: [
      {
        id: "308",
        city: "吉安市",
      },
      {
        id: "113",
        city: "吉林市",
      },
      {
        id: "86",
        city: "济南市",
      },
      {
        id: "122",
        city: "济宁市",
      },
      {
        id: "359",
        city: "佳木斯市",
      },
      {
        id: "74",
        city: "嘉兴市",
      },
      {
        id: "158",
        city: "江门市",
      },
      {
        id: "309",
        city: "江油市",
      },
      {
        id: "72",
        city: "焦作市",
      },
      {
        id: "253",
        city: "金华市",
      },
      {
        id: "261",
        city: "锦州市",
      },
      {
        id: "277",
        city: "晋城市",
      },
      {
        id: "244",
        city: "晋中市",
      },
      {
        id: "304",
        city: "九江市",
      },
    ],
  },
  {
    letter: "K",
    data: [
      {
        id: "70",
        city: "开封市",
      },
      {
        id: "301",
        city: "开远市",
      },
      {
        id: "292",
        city: "科右中旗市",
      },
      {
        id: "351",
        city: "克拉玛依市",
      },
      {
        id: "118",
        city: "昆明市",
      },
    ],
  },
  {
    letter: "L",
    data: [
      {
        id: "88",
        city: "拉萨市",
      },
      {
        id: "287",
        city: "莱芜市",
      },
      {
        id: "63",
        city: "兰州市",
      },
      {
        id: "276",
        city: "廊坊市",
      },
      {
        id: "255",
        city: "乐山市",
      },
      {
        id: "141",
        city: "丽江市",
      },
      {
        id: "153",
        city: "辽阳市",
      },
      {
        id: "150",
        city: "聊城市",
      },
      {
        id: "247",
        city: "临汾市",
      },
      {
        id: "391",
        city: "临海市",
      },
      {
        id: "116",
        city: "临沂市",
      },
      {
        id: "329",
        city: "陵川市",
      },
      {
        id: "164",
        city: "陵水黎族自治县市",
      },
      {
        id: "362",
        city: "柳州市",
      },
      {
        id: "245",
        city: "六盘水市",
      },
      {
        id: "335",
        city: "六枝市",
      },
      {
        id: "311",
        city: "龙海市",
      },
      {
        id: "266",
        city: "龙岩市",
      },
      {
        id: "140",
        city: "陇南市",
      },
      {
        id: "230",
        city: "娄底市",
      },
      {
        id: "378",
        city: "泸州市",
      },
      {
        id: "248",
        city: "吕梁市",
      },
      {
        id: "130",
        city: "洛阳市",
      },
    ],
  },
  {
    letter: "M",
    data: [
      {
        id: "265",
        city: "马鞍山市",
      },
      {
        id: "317",
        city: "茂名市",
      },
      {
        id: "323",
        city: "梅州市",
      },
      {
        id: "384",
        city: "蒙自市",
      },
      {
        id: "157",
        city: "绵阳市",
      },
      {
        id: "160",
        city: "绵阳市江油市",
      },
      {
        id: "156",
        city: "牡丹江市",
      },
      {
        id: "169",
        city: "牡丹江市海林市",
      },
      {
        id: "168",
        city: "牡丹江市绥芬河市",
      },
    ],
  },
  {
    letter: "N",
    data: [
      {
        id: "155",
        city: "内江市",
      },
      {
        id: "68",
        city: "南昌市",
      },
      {
        id: "64",
        city: "南京市",
      },
      {
        id: "91",
        city: "南宁市",
      },
      {
        id: "268",
        city: "南平市",
      },
      {
        id: "99",
        city: "南通市",
      },
      {
        id: "75",
        city: "南阳市",
      },
      {
        id: "306",
        city: "讷河市",
      },
      {
        id: "90",
        city: "宁波市",
      },
      {
        id: "274",
        city: "宁德市",
      },
      {
        id: "369",
        city: "宁武市",
      },
      {
        id: "342",
        city: "农安市",
      },
    ],
  },
  {
    letter: "P",
    data: [
      {
        id: "256",
        city: "攀枝花市",
      },
      {
        id: "135",
        city: "盘锦市",
      },
      {
        id: "314",
        city: "盘州市",
      },
      {
        id: "238",
        city: "平顶山市",
      },
      {
        id: "381",
        city: "萍乡市",
      },
      {
        id: "95",
        city: "莆田市",
      },
      {
        id: "237",
        city: "濮阳市",
      },
      {
        id: "320",
        city: "普洱市",
      },
    ],
  },
  {
    letter: "Q",
    data: [
      {
        id: "296",
        city: "祁县市",
      },
      {
        id: "284",
        city: "祁阳市",
      },
      {
        id: "307",
        city: "齐齐哈尔市",
      },
      {
        id: "235",
        city: "黔东南苗族侗族自治州麻江县市",
      },
      {
        id: "125",
        city: "秦皇岛市",
      },
      {
        id: "371",
        city: "沁水市",
      },
      {
        id: "57",
        city: "青岛市",
      },
      {
        id: "228",
        city: "清远市",
      },
      {
        id: "348",
        city: "琼中市",
      },
      {
        id: "388",
        city: "曲阜市",
      },
      {
        id: "233",
        city: "曲靖市",
      },
      {
        id: "254",
        city: "衢州市",
      },
      {
        id: "103",
        city: "泉州市",
      },
    ],
  },
  {
    letter: "R",
    data: [
      {
        id: "142",
        city: "日照市",
      },
      {
        id: "318",
        city: "荣县市",
      },
      {
        id: "282",
        city: "瑞安市",
      },
    ],
  },
  {
    letter: "S",
    data: [
      {
        id: "267",
        city: "三明市",
      },
      {
        id: "114",
        city: "三亚市",
      },
      {
        id: "358",
        city: "沙湾市",
      },
      {
        id: "380",
        city: "汕头市",
      },
      {
        id: "227",
        city: "汕尾市",
      },
      {
        id: "77",
        city: "商丘市",
      },
      {
        id: "80",
        city: "上海市",
      },
      {
        id: "303",
        city: "韶关市",
      },
      {
        id: "240",
        city: "邵阳市",
      },
      {
        id: "89",
        city: "绍兴市",
      },
      {
        id: "60",
        city: "深圳市",
      },
      {
        id: "81",
        city: "沈阳市",
      },
      {
        id: "97",
        city: "石家庄市",
      },
      {
        id: "249",
        city: "朔州市",
      },
      {
        id: "389",
        city: "四会市",
      },
      {
        id: "344",
        city: "松原市",
      },
      {
        id: "59",
        city: "苏州市",
      },
      {
        id: "299",
        city: "宿迁市",
      },
      {
        id: "290",
        city: "绥芬河市",
      },
    ],
  },
  {
    letter: "T",
    data: [
      {
        id: "365",
        city: "塔城市",
      },
      {
        id: "283",
        city: "台北市",
      },
      {
        id: "109",
        city: "台州市",
      },
      {
        id: "349",
        city: "太仓市",
      },
      {
        id: "66",
        city: "太原市",
      },
      {
        id: "123",
        city: "泰安市",
      },
      {
        id: "167",
        city: "泰州市",
      },
      {
        id: "100",
        city: "唐山市",
      },
      {
        id: "288",
        city: "滕州市",
      },
      {
        id: "54",
        city: "天津市",
      },
      {
        id: "328",
        city: "天门市",
      },
      {
        id: "163",
        city: "铁岭市",
      },
      {
        id: "221",
        city: "铁岭市调兵山市",
      },
      {
        id: "273",
        city: "铜陵市",
      },
      {
        id: "377",
        city: "图们市",
      },
      {
        id: "133",
        city: "吐鲁番市",
      },
      {
        id: "366",
        city: "托里市",
      },
    ],
  },
  {
    letter: "W",
    data: [
      {
        id: "374",
        city: "万宁市",
      },
      {
        id: "297",
        city: "万荣市",
      },
      {
        id: "137",
        city: "威海市",
      },
      {
        id: "357",
        city: "威宁市",
      },
      {
        id: "313",
        city: "涠洲岛市",
      },
      {
        id: "147",
        city: "潍坊市",
      },
      {
        id: "387",
        city: "温岭市",
      },
      {
        id: "93",
        city: "温州市",
      },
      {
        id: "61",
        city: "温州市瑞安市",
      },
      {
        id: "356",
        city: "文山市",
      },
      {
        id: "51",
        city: "乌鲁木齐市",
      },
      {
        id: "98",
        city: "无锡市",
      },
      {
        id: "231",
        city: "吴忠市",
      },
      {
        id: "236",
        city: "梧州市",
      },
      {
        id: "281",
        city: "武汉市",
      },
    ],
  },
  {
    letter: "X",
    data: [
      {
        id: "115",
        city: "西安市",
      },
      {
        id: "382",
        city: "西丰市",
      },
      {
        id: "368",
        city: "锡林浩特市",
      },
      {
        id: "82",
        city: "厦门市",
      },
      {
        id: "336",
        city: "咸宁市",
      },
      {
        id: "121",
        city: "香港特别行政区湾仔区",
      },
      {
        id: "239",
        city: "湘潭市",
      },
      {
        id: "241",
        city: "湘西土家族苗族自治州吉首市",
      },
      {
        id: "275",
        city: "襄阳市",
      },
      {
        id: "250",
        city: "忻州市",
      },
      {
        id: "79",
        city: "新乡市",
      },
      {
        id: "347",
        city: "新沂市",
      },
      {
        id: "259",
        city: "新余市",
      },
      {
        id: "360",
        city: "信丰市",
      },
      {
        id: "171",
        city: "兴安盟突泉县市",
      },
      {
        id: "355",
        city: "兴义市",
      },
      {
        id: "294",
        city: "岫岩市",
      },
      {
        id: "101",
        city: "徐州市",
      },
      {
        id: "69",
        city: "许昌市",
      },
    ],
  },
  {
    letter: "Y",
    data: [
      {
        id: "330",
        city: "牙克石市",
      },
      {
        id: "258",
        city: "雅安市",
      },
      {
        id: "96",
        city: "烟台市",
      },
      {
        id: "219",
        city: "延边朝鲜族自治州珲春市",
      },
      {
        id: "383",
        city: "兖州市",
      },
      {
        id: "302",
        city: "阳城市",
      },
      {
        id: "226",
        city: "阳江市",
      },
      {
        id: "251",
        city: "阳泉市",
      },
      {
        id: "354",
        city: "杨陵市",
      },
      {
        id: "300",
        city: "伊春市",
      },
      {
        id: "269",
        city: "伊犁哈萨克自治州伊宁市",
      },
      {
        id: "321",
        city: "沂源市",
      },
      {
        id: "257",
        city: "宜宾市",
      },
      {
        id: "224",
        city: "宜宾市南溪区",
      },
      {
        id: "166",
        city: "宜宾市屏山县",
      },
      {
        id: "223",
        city: "宜昌市",
      },
      {
        id: "129",
        city: "银川市",
      },
      {
        id: "260",
        city: "鹰潭市",
      },
      {
        id: "262",
        city: "营口市",
      },
      {
        id: "316",
        city: "永丰市",
      },
      {
        id: "242",
        city: "永州市冷水滩区",
      },
      {
        id: "128",
        city: "永州市祁阳县",
      },
      {
        id: "111",
        city: "岳阳市",
      },
      {
        id: "229",
        city: "云浮市",
      },
      {
        id: "252",
        city: "运城市",
      },
    ],
  },
  {
    letter: "Z",
    data: [
      {
        id: "145",
        city: "枣庄市",
      },
      {
        id: "293",
        city: "扎兰屯市",
      },
      {
        id: "225",
        city: "湛江市",
      },
      {
        id: "324",
        city: "张家港市",
      },
      {
        id: "243",
        city: "张家界市",
      },
      {
        id: "83",
        city: "张家口市",
      },
      {
        id: "162",
        city: "漳州市",
      },
      {
        id: "322",
        city: "招远市",
      },
      {
        id: "332",
        city: "昭通市",
      },
      {
        id: "127",
        city: "镇江市",
      },
      {
        id: "390",
        city: "镇雄市",
      },
      {
        id: "334",
        city: "镇远市",
      },
      {
        id: "58",
        city: "郑州市",
      },
      {
        id: "67",
        city: "中山市",
      },
      {
        id: "52",
        city: "重庆市",
      },
      {
        id: "353",
        city: "舟山市",
      },
      {
        id: "280",
        city: "珠海市",
      },
      {
        id: "78",
        city: "驻马店市",
      },
      {
        id: "327",
        city: "庄河市",
      },
      {
        id: "373",
        city: "涿鹿市",
      },
      {
        id: "312",
        city: "资阳市",
      },
      {
        id: "136",
        city: "淄博市",
      },
      {
        id: "234",
        city: "遵义市",
      },
    ],
  },
];

export default list
