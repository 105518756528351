<template>
  <div class="content">
    <van-nav-bar
      title="选择城市"
      left-text="返回"
      left-arrow
      fixed
      safe-area-inset-top
      @click-left="goBack"
    />
  </div>
  <city-list></city-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from 'vue-router'
import CityList from './CityList.vue'

export default defineComponent({
  components: {
    CityList
  },
  setup() {
    const router = useRouter()
    const goBack = () => {
      router.go(-1)
    }
    return {
      goBack
    };
  },
});
</script>


<style lang="less" scoped>
</style>
