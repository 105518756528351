<template>
  <div class="content">
    <!-- <van-sticky>
      <van-search v-model.trim="searchValue" placeholder="请输入搜索关键词" @update:model-value="handleSearch" />
    </van-sticky> -->
    <div class="current-city">
      <van-button type="primary" size="small" block>当前城市：{{$store.state.city.city}}</van-button>
    </div>
    <div class="hot-city">
      <p>热门城市</p>
      <div class="city-list">
        <div class="city" v-for="item in hotCity" :key="item.id">
          <van-button  plain type="primary" block size="small" @click="changeCity(item)">
            {{item.city}}
          </van-button>
        </div>
      </div>
    </div>
    <van-index-bar :index-list="indexList" >
      <template v-for="item in list" :key="item.letter" >
        <van-index-anchor :index="item.letter" />
        <van-cell v-for="city in item.data" :key="city.id" :title="city.city" @click="changeCity(city)"  />
      </template>
    </van-index-bar>
  </div>
</template>

<script lang="ts">
// import api from '@/api'
import { IObject } from '@/types/inerface'
// import { AxiosRequestConfig } from 'axios'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import cityList from '@/utils/cityList'
import list from '@/utils/index.list'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    const indexList = list.map((item) => {
      return item.letter
    })
    const hotCity = [
      { id: "73", city: "北京市" },
      { id: "85", city: "广州市" },
      { id: "60", city: "深圳市" },
      { id: "56", city: "东莞市" },
      { id: "80", city: "上海市" },
      { id: "55", city: "成都市" },
      { id: "280", city: "珠海市" },
      { id: "67", city: "中山市" },
      { id: "65", city: "佛山市" },
    ]
    let state = reactive({
      searchValue: '',
      list: list,
      baseList: list,
      loading: false,
      finished: true
    })
    const onLoad = () => {
      console.log('onLoad 123')
    }
    // const handleGetCityList = () => {
    //   api.bus.cityList().then((res: AxiosRequestConfig['data']) => {
    //     if (res?.code === 0) {
    //       // console.log(res)
    //       state.list = res.data
    //       state.baseList = res.data
    //     }
    //   })
    // }
    // onMounted(() => {
    //   handleGetCityList()
    // })
    const handleSearch = (val:string) => {
      if (val === '') {
        state.list = state.baseList
      } else {
        // state.list = state.list?.filter((item) => {
        //   // console.log(item)
        //   if (item.city.indexOf(val) !== -1) {
        //     console.log(item)
        //     return item
        //   }
        // })
      }
    }

    const changeCity = (city: IObject) => {
      console.log(city)
      store.commit('currentCity', city)
      router.push('/')
    }

    return {
      hotCity,
      indexList,
      changeCity,
      onLoad,
      handleSearch,
      ...toRefs(state)
    }
  },
})
</script>


<style lang="less" scoped>
.content {
  margin-top: 80px;
}

.current-city {
  padding: 16px;
}

.hot-city {
  padding: 16px;
  .city-list {
    display: flex;
    flex-wrap: wrap;
    .city {
      padding: 8px;
      width: 33.3%;
      box-sizing: border-box;
    }
  }
}
</style>
