
import { defineComponent } from "vue";
import { useRouter } from 'vue-router'
import CityList from './CityList.vue'

export default defineComponent({
  components: {
    CityList
  },
  setup() {
    const router = useRouter()
    const goBack = () => {
      router.go(-1)
    }
    return {
      goBack
    };
  },
});
